import Image from "next/image";
import {
  ContainerImageDiv,
  ContainerInfo,
  Data,
  MainContainer,
  MainContainerWrap,
  SubTitle,
  Title,
} from "../../styled-components/utils/containerStyles";
import { infoData } from "./infoData";
import Fade from "react-reveal/Fade";

const infoSection = () => {
  return (
    <main>
      {infoData.map(({ image, title, subTitle, data }, idx) => (
        <MainContainerWrap aria-labelledby={title} key={idx}>
          <MainContainer idx={idx}>
            <Fade bottom delay={idx % 2 === 0 ? 0 : 200}>
              <ContainerInfo>
                <Title id={title.replace(/\s+/g, "")}>{subTitle}</Title>
                {title && <SubTitle>{title}</SubTitle>}
                {data.map((dat, idx) => (
                  <Data key={idx}>{dat}</Data>
                ))}
              </ContainerInfo>
            </Fade>
            <Fade bottom delay={idx % 2 !== 0 ? 0 : 200}>
              <ContainerImageDiv>
                <Image
                  width={image.width}
                  height={image.height}
                  fill="responsive"
                  src={image.src}
                  alt="home image"
                />
              </ContainerImageDiv>
            </Fade>
          </MainContainer>
        </MainContainerWrap>
      ))}
    </main>
  );
};

export default infoSection;
