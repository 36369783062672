import { NextSeo } from "next-seo";
import HeroSection from "../components/home/hero";
import InfoSection from "../components/home/infoSection";
import WhyUs from "../components/home/whyUs";
import logo from "../public/cropped-va-law-offices-logo-32x32.png";
import { homeData } from "../Layout/seo/PagesBaseData";

export default function Home() {
  return (
    <>
      <NextSeo
        title={homeData.title}
        description={homeData.description}
        canonical={homeData.url}
        openGraph={{
          url: homeData.url,
          title: homeData.title,
          description: homeData.description,
          images: [
            {
              url: process.env.NEXT_PUBLIC_BASE_URL + logo.src,
              width: logo.width,
              height: logo.height,
              alt: "Vidhigya Associates Logo",
              type: "image/jpeg",
            },
          ],
          site_name: "Vidhigya Associates",
        }}
      />
      <HeroSection />
      <InfoSection />
      <WhyUs />
    </>
  );
}
