import { Fade } from "react-reveal";
import styled from "styled-components";
import withReveal from "react-reveal/withReveal";

export const HeroContainer = styled.header`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: ${({ short, half }) =>
    short ? "calc(100vh - 80px)" : half ? "50vh" : "100vh"};
  position: relative;
  z-index: 1;
  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 1) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.1) 100%, transparent 10%);
    z-index: 2;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const HeroContent = styled.div`
  z-index: 3;
  width: 100%;
  margin: auto;
  max-width: 1240px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  justify-content: ${({ centerText }) =>
    centerText ? "center" : "flex-start"};
  align-items: ${({ centerText }) => (centerText ? "center" : "flex-start")};
  flex-direction: ${({ flow }) => flow || "row"};
  justify-content: space-evenly;
`;

export const HeroH1 = withReveal(
  styled.h1`
    color: #fff;
    width: 100%;
    font-size: 48px;
    text-align: ${({ centerText }) => (centerText ? "center" : "left")};
    line-height: 58px;
    margin: ${({ centerText }) => (centerText ? "auto" : "0")};
    max-width: 600px;
    font-family: "Times New Roman", Times, serif;
    font-weight: 600;

    @media screen and (max-width: 768px) {
      font-size: 40px;
    }

    @media screen and (max-width: 480px) {
      font-size: 32px;
    }
  `,
  <Fade bottom duration={500} />
);

export const HeroP = styled.p`
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
