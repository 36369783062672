import {
  HeroBg,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
} from "../../styled-components/Home/heroStyles";
import HeroBgImg from "../../images/why-us.jpg";
import {
  Reason,
  ReasonData,
  Reasons,
  ReasonTitle,
} from "../../styled-components/Home/reasonStyles";
import { reasonsData } from "./infoData";
import Image from "next/image";
import { Fade } from "react-reveal";

const whyUs = () => {
  return (
    <HeroContainer short={true}>
      <HeroBg>
        <Image
          alt="Why us Background image"
          src={HeroBgImg.src}
          layout="fill"
          objectFit="cover"
          quality={100}
          priority={true}
        />
      </HeroBg>
      <HeroContent flow={"column"}>
        <Fade delay={200} left>
          <HeroP style={{ marginBottom: "-10px" }}>
            ‘Professional and Experienced Law Attorney
          </HeroP>
        </Fade>
        <br />
        <Fade left>
          <HeroH1 style={{ marginBottom: "25px" }}>Why choose our firm</HeroH1>
        </Fade>
        <Reasons>
          <Fade cascade bottom delay={300}>
            {reasonsData.map(({ title, data }, idx) => (
              <Reason number={`0${idx + 1}`} key={idx}>
                <ReasonTitle>{title}</ReasonTitle>
                <ReasonData>{data}</ReasonData>
              </Reason>
            ))}
          </Fade>
        </Reasons>
      </HeroContent>
    </HeroContainer>
  );
};

export default whyUs;
