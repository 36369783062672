export const homeData = {
  title: "V.A Law Office",
  description:
    "V.A Law Offices is full-service law firm situated in Navi Mumbai, India. The firm provides services extensively in the areas of legal practices.",
  url: process.env.NEXT_PUBLIC_BASE_URL,
};

export const practiceAreaPageData = {
  title: "V.A Law Office | Practice Area",
  description:
    "V.A Law Offices is full-service law firm situated in Navi Mumbai, India. The firm provides services extensively in the areas of legal practices.",
  url: process.env.NEXT_PUBLIC_BASE_URL + "/practice-area",
};

export const servicesPageData = {
  title: "V.A Law Office | Services",
  description:
    "V.A Law Offices is full-service law firm situated in Navi Mumbai, India. The firm provides services extensively in the areas of legal practices.",
  url: process.env.NEXT_PUBLIC_BASE_URL + "/services",
};

export const careersPageData = {
  title: "V.A Law Office | Careers",
  description:
    "V.A Law Offices is full-service law firm situated in Navi Mumbai, India. The firm provides services extensively in the areas of legal practices.",
  url: process.env.NEXT_PUBLIC_BASE_URL + "/careers",
};
