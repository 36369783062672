import home from "../../images/home-page-1.jpg";
import HeroBgImg from "../../images/HeroBg.jpg";

export const infoData = [
  {
    image: home,
    title: "Professional and Experienced Law Attorney",
    subTitle: "You've Come To The Right Place",
    data: [
      "V.A Law Offices is full-service law firm situated in Navi Mumbai, India. The firm provides services extensively in the areas of legal practices. The key objective behind setting up V.A Law Offices is to render finest legal and commercial advices, protecting rights and resolving disputes. We deliver services of the highest quality, which are capable of meeting the most onerous standards. V.A Law Offices possesses adequate resources to handle substantial transactions, and provides value added services to their clients.",
      "Our lawyers are highly skilled, proficient and well versed with legal knowledge and have an excellent grip on local jurisdiction. We have expert lawyers specialized in different practice areas, benefiting from experience of the older generation and fresh ideas of the younger generation to provide a unique combination of skills and expertise to help clients resolve their most complex legal and commercial issues.",
    ],
  },
  {
    image: HeroBgImg,
    title: "Vidhigya Associates",
    data: [
      "The word Vidhigya means Jurist or a Specialist in Law. We believe that the name articulates our quality and work. We constantly learn through our interactions with seasoned practitioners and seek to adopt best practices in legal services delivery. Vidhigya values the satisfaction of clients and consider it as its key measure of success.",
    ],
  },
];

export const reasonsData = [
  {
    title: "Excellent Track Record",
  },
  {
    title: "Transparent Fees",
  },
  {
    title: "Unparalleled Customer Service",
  },
];
