import React, { useContext } from "react";
import {
  HeroBg,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
} from "../../styled-components/Home/heroStyles";
import HeroBgImg from "../../images/HeroBg.jpg";
import Image from "next/image";
import { Fade } from "react-reveal";
import { UserContext } from "../../context";

const HeroSection = () => {
  const { isModalOpen } = useContext(UserContext);

  return (
    <HeroContainer>
      <HeroBg>
        <Image
          priority={true}
          alt="Home Background image"
          src={HeroBgImg.src}
          layout="fill"
          objectFit="cover"
          quality={100}
        />
      </HeroBg>
      <HeroContent flow={"column"}>
        <Fade bottom delay={300} when={!isModalOpen}>
          <HeroP>Committed to Helping Our Clients Succeed</HeroP>
        </Fade>
        <br />
        <Fade bottom duration={200} when={!isModalOpen}>
          <HeroH1>
            You Need A Reliable Law Firm That Helps Deal With Your Legal
            Affairs.
          </HeroH1>
        </Fade>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
