import styled from "styled-components";

export const Reasons = styled.div`
  display: flex;
  flex-flow: column;
  margin: 1rem auto 0 15rem;
  margin-left: clamp(-20px, 25vw, 15rem);
  @media screen and (max-width: 820px) {
    /* margin: 1rem auto 0; */
  }
`;

export const Reason = styled.div`
  display: flex;
  line-height: 1;
  margin-bottom: 2rem;
  flex-flow: column;
  position: relative;
  &:before {
    position: absolute;
    content: ${({ number }) => `"${number}"`};
    /* width: 100%; */
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    left: -100px;
    font-size: 3rem;
    font-weight: bolder;
  }
  @media screen and (max-width: 820px) {
    &:before {
      left: -70px;
      font-size: 2.5rem;
    }
  }
`;

export const ReasonTitle = styled.h2`
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 24px;
`;

export const ReasonData = styled.p`
  font-weight: 400;
  max-width: 500px;
  font-size: 16px;
`;
